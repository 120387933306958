<template>
  <div class="system-view">
    <div class="main-section">
      <template v-for="(item, index) in datas">
        <div v-if="item.length > 0" :key="index" class="section">
          <div class="section-title"><div>{{ moduleIdMap[index] }}</div><div class="line"></div></div>
          <div v-for="(el, i) in item" :key="i" class="item">
            <el-switch v-model="el.status" :active-value="1" :inactive-value="0" @change="handlerChange(el, $event)"></el-switch>
            {{ el.contents[0] }}
            <el-input size="mini" type="number" v-model="el.number" @change="handlerChange(el, $event)"></el-input>
            {{ el.contents[1] }}
            <span style="margin-left: 20px;">首次推送给</span>
            <el-select multiple v-model="el.firstRoleIds" style="width:300px;" @change="handlerChange(el, $event)">
              <el-option
                v-for="role in roleList"
                :key="role.id"
                :label="role.roleName"
                :value="role.id">
              </el-option>
            </el-select>
            <template v-if="typeof el.intervalValue == 'number'">
              <span style="margin-left: 20px;">间隔</span>
              <el-input size="mini" type="number" v-model="el.myIntervalValue" @change="handlerChange(el, $event)"></el-input>
              <el-select v-model="el.intervalValueType" style="width:70px;" placeholder="" @change="handlerChange(el, $event)">
                <el-option label="天" value="天"> </el-option>
                <el-option label="时" value="时"> </el-option>
                <el-option label="分" value="分"> </el-option>
              </el-select>
              <span style="margin-left: 20px;">再次推送给</span>
              <el-select multiple v-model="el.secondRoleIds" style="width:300px;" @change="handlerChange(el, $event)">
                <el-option
                  v-for="role in roleList"
                  :key="role.id"
                  :label="role.roleName"
                  :value="role.id">
                </el-option>
              </el-select>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'system-basics',
  data () {
    return {
      moduleIdMap: {
        '1': '安全管理',
        '2': '人力资源',
        '3': '设备管理',
        '4': '生产管理'
      },
      datas: {
        '1': [],
        '2': [],
        '3': [],
        '4': []
      },
      roleList: []
    }
  },
  methods: {
    /** 获取初始数据 */
    queryData () {
      this.$http({
        url: this.$http.adornUrl('/job/rule/list'),
        method: 'post',
        data: {}
      }).then((data) => {
        if (data && data.code === 200) {
          let obj = {
            '1': [],
            '2': [],
            '3': [],
            '4': []
          }
          data.datas.map(item => {
            if (typeof item.content === 'string') {
              item.contents = item.content.split('%s')
              item.firstRoleIds = item.firstRoleId.split(',').map(el => +el)
              if (typeof item.secondRoleId === 'string') {
                item.intervalValueType = '天'
                item.myIntervalValue = +(item.intervalValue / 1440).toFixed(2)
                item.secondRoleIds = item.secondRoleId.split(',').map(el => +el)
              }
              obj['' + item.moduleId].push(item)
            } else {
              console.log('无效规则信息 -- ', item)
            }
          })
          this.datas = obj
        } else {
          console.log(data)
        }
      })
    },
    /** 获取角色列表 */
    queryRoles () {
      this.$http({
        url: this.$http.adornUrl('/user/role/listForPage'),
        method: 'post',
        data: {
          pageNum: 1,
          pageSize: 2000
        }
      }).then(data => {
        if (data.code === 200) {
          this.roleList = data.datas.list || []
        } else {
          console.log(data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    /** 改变配置时提交update */
    handlerChange (item) {
      console.log(JSON.parse(JSON.stringify(item)))
      let params = {
        id: item.id,
        number: item.number,
        status: item.status,
        firstRoleId: item.firstRoleIds.join(','),
        intervalValue: item.intervalValue,
        secondRoleId: item.secondRoleId
      }
      if (typeof item.intervalValue === 'number') {
        params.secondRoleId = item.secondRoleIds.join(',')
        switch (item.intervalValueType) {
          case '天':
            params.intervalValue = item.myIntervalValue * 1440
            break
          case '时':
            params.intervalValue = item.myIntervalValue * 60
            break
          default:
            params.intervalValue = item.myIntervalValue
        }
      }
      this.$http({
        url: this.$http.adornUrl('/job/rule/update'),
        method: 'post',
        data: params
      }).then(data => {
        if (data.code === 200) {
          console.log('保存成功')
        }
        console.log(data.msg)
      }).catch(err => {
        console.log(err)
      })
    }
  },
  created () {
    this.queryRoles()
    this.queryData()
  }
}
</script>

<style lang="scss" scoped>
.system-view {
  padding: 30px 50px;
  .main-section {
    padding: 18px;
    padding-bottom: 1px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    background-color: #fff;
    .section-title {
      font-weight: bold;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .line {
        margin-left: 20px;
        flex: 1;
        background-color: #ddd;
        height: 2px;
      }
    }
    .item {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #EBEEF5;
    }
    .section {
      .item:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
